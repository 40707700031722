import { NextPage } from "next";
import RobeHeading from "components/ui/RobeHeading";
import { Container } from "anolis-ui";
import Page from "components/layouts/Page";
import { ssgProps } from "utils/ssgProps";
import { FormattedMessage } from "react-intl";
import { useTagManager } from "utils/useTagManager";

const LoginPage: NextPage = () => {
  useTagManager("login");

  return (
    <Page>
      <Container pt={{ _: "3rem", sm: "4rem" }} pb={{ _: "3rem", sm: "6rem" }}>
        <RobeHeading t="h2" as="h1">
          <FormattedMessage id="login.title" defaultMessage="Login" />
        </RobeHeading>
      </Container>
    </Page>
  );
};

export default LoginPage;

export const getStaticProps = ssgProps();
